import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export type FormHookTextFieldProps = Omit<TextFieldProps, "name"> & {
  name: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  errorMessage?: string;
  value?: number | string | null;
  min?: number;
  max?: number | string;
  textAdornment?: string;
  showTextAdornment?: boolean;
};

const FormHookTextField = ({
  name,
  label,
  placeholder,
  maxLength,
  min,
  max,
  errorMessage,
  textAdornment,
  showTextAdornment = false,
  ...rest
}: FormHookTextFieldProps) => {
  const { control } = useFormContext();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          size="medium"
          type={!isShowPassword && name === "password" ? "password" : "text"}
          InputProps={{
            inputProps: { maxLength, min, max },
            endAdornment: name === "password" && (
              <IconButton
                edge="end"
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            ),
          }}
          {...rest}
        />
      )}
    />
  );
};

export default FormHookTextField;
