import { Box, Button, Typography } from "@mui/material";
import FormHookTextField from "../../components/FormHookTextField/FormHookTextField";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaLogin } from "../../helpers/schema.helpers";
import Layout from "../../components/Layout/Layout";
import { useAppDispatch } from "../../redux/store";
import { postLogin } from "../../redux/web.slice";
import { LoginDataDef } from "../../types/type";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { useRouter } from "next/router";

const Login = () => {
  const [messageError, setMessageError] = useState<string>("");

  const dispatch = useAppDispatch();
  const router = useRouter();

  const methods = useForm<LoginDataDef>({
    mode: "onBlur",
    resolver: yupResolver(schemaLogin),
  });

  const {
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: LoginDataDef) => {
    const dataLogin = {
      ...data,
      grantType: "password",
    };
    const response = await dispatch(postLogin(dataLogin));
    if (postLogin.fulfilled.match(response)) {
      setMessageError("");
      return router.push("/");
    }
    return setMessageError(
      "Thông tin đăng nhập không chính xác. Vui lòng thử lại!"
    );
  };

  return (
    <Layout isHiddenBanner>
      <Box maxWidth={600} mx="auto" p={2}>
        <Box
          mt={4}
          px={3}
          py={4}
          bgcolor="white"
          boxShadow="0px 10px 40px 0px rgb(136 136 136 / 35%)"
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormHookTextField
                label="Email *"
                name="email"
                fullWidth
                errorMessage={errors.email?.message}
                onClick={() => setMessageError("")}
              />
              <Box my={2}>
                <FormHookTextField
                  label="Mật khẩu *"
                  name="password"
                  fullWidth
                  errorMessage={errors.password?.message}
                  onClick={() => setMessageError("")}
                />
              </Box>
              {messageError && (
                <Box mb={1} display="flex" alignItems="center">
                  <ErrorIcon color="error" fontSize="small" />
                  <Typography ml={0.5} mt={0.2} color="error" fontSize={12}>
                    {messageError}
                  </Typography>
                </Box>
              )}
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Đăng nhập
              </Button>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Layout>
  );
};

export default Login;
