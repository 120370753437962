import * as yup from "yup";

export const schemaExam = yup
  .object({
    customer: yup.object({
      name: yup.string().trim().required("Vui lòng nhập họ và tên!"),
      email: yup
        .string()
        .trim()
        .required("Vui lòng nhập đúng định dạng email!")
        .matches(
          /^(?:[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[^@][a-zA-Z]{1,4})?$/,
          "Vui lòng nhập đúng định dạng email!"
        ),
      phone: yup
        .string()
        .trim()
        .matches(/^[0-9]*$/, "Vui lòng nhập đúng định dạng số điện thoại!")
        .test(
          "len",
          "Vui lòng nhập đúng định dạng số điện thoại!",
          (val) => val?.length === 10 || val?.length === 11
        ),
      address: yup.string().trim().required("Vui lòng nhập địa chỉ!"),
    }),
  })

  .required();

export const schemaLogin = yup.object({
  email: yup.string().required("Vui lòng nhập đúng định dạng email!"),
  password: yup.string().required("Vui lòng nhập mật khẩu! "),
});
